<template>
  <div class="mb-4">
      <CRow class="mb-0">
        <CCol lg="3">
          <CInput v-model="name" placeholder="Name"/>
        </CCol>
        <CCol lg="3">
          <v-select 
            label="setting_name" 
            :options="personnelTypeList.data"
            :reduce="item => item.id"
            v-model="personnel_type"
            placeholder="Personnel Type"
          > 
          </v-select> 
        </CCol>
       <CCol lg="3">
          <v-select 
            label="setting_name" 
            :options="depotList.data"
            :reduce="item => item.id"
            v-model="depot_id"
            placeholder="Depot"
          > 
          </v-select>  
        </CCol>
        <CCol lg="1">
        </CCol>
        <CCol lg="2" class="float-right">
          <CButton size="sm" block color="info" @click="search"><i class="icon-magnifier"></i> Search</CButton>
        </CCol>
      </CRow>
      <CRow>
        
        <CCol lg="3">
          <v-select 
            label="label" 
            :options="[
              
              { 
                value: 'direct_hire', 
                label: 'Direct Hire'
              },
              { 
                value: 'outsourced', 
                label: 'Outsourced'
              }
            ]" 
            :reduce="item => item.value"
            v-model="employment_type"
            placeholder="Employment Type"
          > 
          </v-select>  
        </CCol>
        <CCol lg="3">
          <CInput v-model="contact_no" placeholder="Contact"/>
        </CCol>
        <CCol lg="3">
         <v-select 
            label="vendor_name" 
            :options="vendorList.data"
            :reduce="item => item.id"
            v-model="vendor_id"
            placeholder="Vendor"
          > 
          </v-select>
        </CCol>
        <CCol lg="1">
        </CCol>
        <CCol lg="2" class="float-right">
          <CButton size="sm" block @click="clearSearch" color="info">Clear Search</CButton>
        </CCol>
      </CRow>
  </div>
</template>

<script>
  import config from '../../config.js';
  import axios from '../../axios';
  import vSelect from 'vue-select'
  import 'vue-select/dist/vue-select.css';
    export default {
        name: "BookingTypeSearch",
        data() {
          return {
            name: "",
            personnel_type: "",
            home_address: "",
            depot_id: "",
            employment_type: "",
            contact_no: "",
            vendor_id : "",

            depotList: {
              data: []
            },
            personnelTypeList: {
              data: []
           },
             vendorList: {
            	data: []
            },
            
          }
        },
      mounted() {
        this.getDepot();
        this.getPersonnelType();
        this.getVendor();
      },
      components: {vSelect},
      methods: {
        search() {
          const data = {
            name: this.name,
            personnel_type: this.personnel_type,
            depot_id: this.depot_id,
            home_address: this.home_address,
            contact_no: this.contact_no,
            employment_type: this.employment_type,
            vendor_id : this.vendor_id
          }
          this.$emit("depot-search-query", data)
        },
        clearSearch() {
          this.name = "";
          this.personnel_type = "";
          this.depot_id = "";
          this.home_address = "";
          this.contact_no = "";
          this.employment_type = "";
          this.vendor_id = ""
          const data = {
            name: this.name,
            personnel_type: this.personnel_type,
            depot_id: this.depot_id,
            home_address: this.home_address,
            contact_no: this.contact_no,
            employment_type: this.employment_type,
            vendor_id: this.vendor_id,
          }
          this.$emit("depot-search-query", data)
        },
        updatePersonnelType(data){
          this.personnel_type = data;
        },
        updateDepot(data){
          this.depot_id = data;
        },
        updateEmploymentType(data){
          employment_type = data;
        },

        getDepot(){
          axios.get(config.api_path+'/reference/settings-list',{
              params:{
                setting_type:'depot'
              }
                })
          .then(response => {
            this.depotList = response.data; 
          })

          },

        // getDepot(){

        //   axios.get(config.api_path+'/setting?setting_type=depot&page=1&limit=10000000')
        //   .then(response => {
        //     this.depotList = response.data;
        //     this.depotList.data = this.depotList.data.map((value, index)=>{
        //       value.value = value.id;
        //       value.label = value.setting_name;
        //       return value;
        //     })
        //     this.depotList.data.unshift({
        //       id: "all",
        //       setting_name: "All"
        //     })
        //   })

        // },
        getVendor(){
            axios.get(config.api_path+'/reference/vendors-list')
             .then(response => {
              this.vendorList = response.data; 
          })

          },

      //    getVendor(){

	    // 	axios.get(config.api_path+'/vendor?page=1&limit=10000000')
	    // 	.then(response => {
	    // 		this.vendorList = response.data; 
	    // 		this.vendorList.data.unshift({
	    // 			vendor_name: 'All',
	    // 			id: 'all'
	    // 		})
	    // 	})

	    // },
      getPersonnelType(){
          axios.get(config.api_path+'/reference/settings-list',{
              params:{
                setting_type:'personnel_type'
              }
                })
          .then(response => {
            this.personnelTypeList = response.data; 
          })

          },
        // getPersonnelType(){

        //   axios.get(config.api_path+'/setting?setting_type=personnel_type&page=1&limit=10000000')
        //   .then(response => {
        //     this.personnelTypeList = response.data;
        //     this.personnelTypeList.data = this.personnelTypeList.data.map((value, index)=>{
        //       value.value = value.id;
        //       value.label = value.setting_name;
        //       return value;
        //     })
        //     this.personnelTypeList.data.unshift({
        //       id: "all",
        //       setting_name: "All"
        //     })
        //   })

        // },
      }
    }
</script>

<style scoped>

</style>
