<template>
	<div>
		<CCard class="p-4">
		<CRow>
			
			<CCol lg="6">
					<h5>Personnel ({{count}})</h5>
			</CCol>
	     		 <CCol lg="6">
					<CButton size="sm" style="float: right;" :class="'float-lg-right block '+showCollapse ? 'collapsed' : null"
			                 :aria-expanded="showCollapse ? 'true' : 'false'"
			                 aria-controls="collapse1"
			                 @click="showCollapse = !showCollapse"
			                 color="info"><i class="fa fa-search"></i>Filter<i :class="showCollapse ? 'fa fa-caret-up' : 'fa fa-caret-down'"></i></CButton>
			        <CButton 
					 v-if="config.getPermission('personnel').create"
					 size="sm" style="margin-right: 10px" 
					 target="_blank " href="#/data/create_personnel" 
					 color="success"  
					 class="float-lg-right">
					 <i class="fa fa-plus"></i> Personnel</CButton>
					 <!-- @click="toggleModal()" -->
					 <CButton 
					 v-if="config.getPermission('personnel').download"
					 size="sm" style="margin-right: 10px" 
					 color="primary" @click="download()" 
					 class="float-lg-right">
					 <i class="fa fa-download"></i> Download</CButton>

		      	
			    </CCol>
		</CRow>
		<CRow> 
				<CCol lg="12">
					<CCollapse id="collapse1" :show="showCollapse" class="mt-2">
				        <CCard class="p-4">
				          <Search @depot-search-query="search"/>
				        </CCard>
				      </CCollapse> 
				</CCol>
		</CRow> 
		<hr>

		<CRow>			
	     
		      <CCol lg="12">  
				   <div class="table-responsive-sm table-responsive-md"	>
	      		<CTabs variant="pills" :active-tab="0">
					   <CTab title="All" @click.native="changeSetting('')">
				    	<br/>
						<!-- <CCol lg="12" class="text-center" v-if="isLoading"> 
						 	 <div class="spinner-border m-5" role="status">
							 <span class="sr-only">Loading...</span>
							</div>
						</CCol> -->
				      	<CDataTable
						  	:loading="isLoading"
					      	:items="dataListAll.data"
					      	:fields="fields"
							hover
							striped
							outlined	
							
					    >  
					    	<template #status="{item}">
								 <td style="padding: 5px; width: 100px; text-transform:uppercase;">
            					<CBadge 
								 style="margin-top:10px"		
									:color="getBadge(item.status)">{{item.status}}</CBadge>
         						 </td>
							</template>
					      	<template #action="{item}">
						        <td style="padding: 5px; width: 100px">
						        	<CDropdown 
						                toggler-text="Select"  
										size="sm"
						                color="info"
						            >
						            	<CDropdownItem v-if="config.getPermission('personnel').view" @click="viewModal(item)"><i class="fa fa-eye">View </i></CDropdownItem> 
						                <CDropdownItem v-if="config.getPermission('personnel').update" @click="updateModal(item)"><i class="fa fa-pencil-square-o"> Edit</i></CDropdownItem>
						                <CDropdownItem v-if="config.getPermission('personnel').delete" @click="deleteRow(item)"><i class="fa fa-trash"> Delete </i></CDropdownItem> 
						            </CDropdown>
						        </td>
					      	</template>
					    </CDataTable> 
					    <CPagination
				          	:active-page.sync="currentPageAll"
				          	:pages="Math.ceil(dataListAll.total / 20)"
				          	:activePage="currentPageAll"
				          	@update:activePage="updatePageAll"
				        />
				    </CTab>
				    <CTab title="Active" @click.native="changeSetting('active')">
				    	<br/>
						<!-- <CCol lg="12" class="text-center" v-if="isLoading"> 
						 	 <div class="spinner-border m-5" role="status">
							 <span class="sr-only">Loading...</span>
							</div>
						</CCol> -->
				      	<CDataTable
						 	 :loading="isLoading"
					      	:items="dataList.data"
					      	:fields="fields"
							hover
							striped
							outlined
							
							
					    >  
					    	<!-- <template #status="{item}">
						        <td style="padding: 5px; width: 100px">
						        	<CBadge style="display: block; width: 60px; margin-left: auto; margin-right: auto" color="primary" v-if="item.status == 'active'">Active</CBadge>
						        	<CBadge style="display: block; width: 60px; margin-left: auto; margin-right: auto" color="danger" v-else>Inactive</CBadge>
						        </td>
					      	</template> -->
							  <template #status="{item}">
								 <td style="padding: 5px; width: 100px; text-transform:uppercase;">
            					<CBadge 
								 style="margin-top:10px"		
									:color="getBadge(item.status)">{{item.status}}</CBadge>
         						 </td>
							</template>
					      	<template #action="{item}">
						        <td style="padding: 5px; width: 100px">
						        	<CDropdown 
						                toggler-text="Select"  
										size="sm"
						                color="info"
						            >
						            	<CDropdownItem v-if="config.getPermission('personnel').view" @click="viewModal(item)"><i class="fa fa-eye">View </i></CDropdownItem> 
						                <CDropdownItem v-if="config.getPermission('personnel').update" @click="updateModal(item)"><i class="fa fa-pencil-square-o"> Edit</i></CDropdownItem>
						                <CDropdownItem v-if="config.getPermission('personnel').delete" @click="deleteRow(item)"><i class="fa fa-trash"> Delete </i></CDropdownItem>  
						            </CDropdown>
						        </td>
					      	</template>
					    </CDataTable> 
					    <CPagination
				          	:active-page.sync="currentPage"
				          	:pages="Math.ceil(dataList.total / 20)"
				          	:activePage="currentPage"
				          	@update:activePage="updatePage"
				        />
				    </CTab>
				    <CTab title="Inactive" @click.native="changeSetting('inactive')">
				    	<br/>
						<!-- <CCol lg="12" class="text-center" v-if="isLoading"> 
						 	 <div class="spinner-border m-5" role="status">
							 <span class="sr-only">Loading...</span>
							</div>
						</CCol> -->
				      	<CDataTable
						  :loading="isLoading"
					      	:items="dataListInactive.data"
					      	:fields="fields"
							hover
							striped
							outlined
							
					    >  
					    	<template #status="{item}">
								 <td style="padding: 5px; width: 100px; text-transform:uppercase;">
            					<CBadge 
								 style="margin-top:10px"		
									:color="getBadge(item.status)">{{item.status}}
								</CBadge>
         						 </td>
							</template>
					      	<template #action="{item}">
						        <td style="padding: 5px; width: 100px">
						        	<CDropdown 
						                toggler-text="Select"  
										size="sm"
						                color="info"
						            >
						            	<CDropdownItem v-if="config.getPermission('personnel').view" @click="viewModal(item)"><i class="fa fa-eye">View </i></CDropdownItem> 
						                <CDropdownItem v-if="config.getPermission('personnel').update" @click="updateModal(item)"><i class="fa fa-pencil-square-o"> Edit</i></CDropdownItem>
						                <CDropdownItem v-if="config.getPermission('personnel').delete" @click="deleteRow(item)"><i class="fa fa-trash"> Delete </i></CDropdownItem> 
						            </CDropdown>
						        </td>
					      	</template>
					    </CDataTable> 
					    <CPagination
				          	:active-page.sync="currentPageInactive"
				          	:pages="Math.ceil(dataListInactive.total / 20)"
				          	:activePage="currentPageInactive"
				          	@update:activePage="updatePageInactive"
				        />
				    </CTab> 
				</CTabs> 
			</div>
			  </CCol>
	    </CRow>
		<!-- ADD/UPDATE -->
	    <CModal :title="title"  :show.sync="formModal" color="info" size="lg">
			
           <fieldset class="fieldset full-line">
			 <legend>Personnel Details</legend>
           		
           		<CRow>
					<CCol lg="12" class="mb-2">
						<label>Name <span class="text-danger">*</span></label>	
						<CInput
						class="mb-0"
		                
		                  v-model="dataParams.name"
		                  required="true"  
		                />	
						 <small class="text-info">
						 Name should be in CAPITAL LETTERS. And the format should be (LAST NAME, FIRSTNAME MIDDLE NAME SUFFIX. Ex. RAMISO, APRIL R.)
					</small>
					</CCol>
					
				</CRow>
				<CRow>
					<CCol lg="12">
						<CInput
		                  label="Home Address"
		                 
		                  v-model="dataParams.home_address"  
		                />
					</CCol> 
				</CRow>
				<CRow>
					<CCol lg="6">
						<label>Contact Number </label>
						<CInput
		                 
		                  v-model="dataParams.contact_no"
		                
						  
		                />
					</CCol>
					<CCol lg="6">
						<label>Email Address </label>
						<CInput
		                  label=""
		                 
		                  v-model="dataParams.email"
		                  type="email"
		                />
					</CCol>
					
				</CRow>
				
				<CRow>
					<CCol lg="6">
						<div class="form-group">
							<label for="exampleInputEmail1">Birthdate</label>
							<Datepicker 
								input-class="form-control bg-white" 
								v-model="dataParams.birthday"
								
								:config="options"
							>
							</Datepicker> 
						</div> 
					</CCol>
					<CCol lg="6">
						<div class="form-group">
							<label for="gender">Gender</label>
							<CInputRadioGroup  
			                    :options="[
			                    	{ 
										value: 'male', 
										label: 'Male'
							        },
							        { 
										value: 'female', 
										label: 'Female'
							        }
			                    ]" 
			                    :checked="dataParams.gender"
			                    @update:checked="updateGender"
			                    :inline="true"
			
								
			                />
							
			            </div>
					</CCol>
					
				</CRow>
				<CRow>

					<CCol lg="6">
						<div class="form-group">
							<label for="exampleInputEmail1">Status <span class="text-danger">*</span></label> 
							<CInputRadioGroup  
			                    :options="[
			                    	{ 
										value: 'active', 
										label: 'Active'
							        },
							        { 
										value: 'inactive', 
										label: 'Inactive'
							        }
			                    ]" 
			                    :checked="dataParams.status"
			                    @update:checked="updateStatus"
			                    :inline="true" 
			                />
			            </div>
					</CCol>
				</CRow>
			</fieldset>
			<fieldset class="fieldset full-line">
			 <legend>Employment Details</legend>
				<!-- <hr/> -->
			
				<CRow>
					<CCol lg="6">
						<div class="form-group">
							<label>Personnel Type <span class="text-danger">*</span></label>
							<v-select 
								label="setting_name" 
								:options="personnelTypeList.data"
								:reduce="item => item.id"
								v-model="dataParams.personnel_type"
								placeholder="--Select--"
							>
								<template #search="{attributes, events}">
									<input
										class="vs__search"
										:required="!dataParams.personnel_type"
										v-bind="attributes"
										v-on="events"
									/>
								</template>
							</v-select>
						</div> 
					</CCol>
					<CCol lg="6">
						<div class="form-group">
							<label for="employment_type">Employment Type <span class="text-danger">*</span></label>
							<CInputRadioGroup  
			                    :options="[
			                    	{ 
										value: 'direct_hire', 
										label: 'Direct Hire'
							        },
							        { 
										value: 'outsourced', 
										label: 'Outsourced'
							        }
			                    ]" 
			                    :checked="dataParams.employment_type"
			                    @update:checked="updateEmploymentType"
			                    :inline="true" 
			                    required="true"
			                />
			            </div>
					</CCol>
				</CRow>
				<CRow v-if="dataParams.employment_type == 'outsourced'">
					<CCol lg="12">
						<label>Vendor <span class="text-danger">*</span></label>
						<CSelect
							
							:options="vendorList.data" 
							:value="dataParams.vendor_id"
							placeholder="--Select--"
							:required="dataParams.employment_type == 'outsourced'"
							@update:value="updateVendor"
		                />
					</CCol>
				</CRow> 
				<CRow>
					<CCol lg="6">
						<CInput
		                  label="Employee ID"
		                  v-model="dataParams.employee_id" 
		                />
					</CCol>
					<CCol lg="6"> 
						<div class="form-group"> 
							<label>Depot <span class="text-danger">*</span></label>
							<v-select 
								:disabled="depot_id != null"
								label="setting_name" 
								:options="depotList.data"
								:reduce="item => item.id"
								v-model="dataParams.depot_id"
								placeholder="--Select--"
							>
								<template #search="{attributes, events}">
									<input
										class="vs__search"
										:required="!dataParams.depot_id"
										v-bind="attributes"
										v-on="events"
									/>
								</template>
							</v-select>
						</div>  
					</CCol>
				</CRow>
				<CRow>
					<CCol lg="6">
						<div class="form-group">
							<label for="exampleInputEmail1">Date Started</label>
							<Datepicker 
								input-class="form-control bg-white" 
								v-model="dataParams.date_started"
								
								:config="options"
							>
							</Datepicker> 
						</div> 
					</CCol>
					<CCol lg="6">
						<div class="form-group">
							<label for="exampleInputEmail1">Date of Separation <span class="text-danger" v-if="dataParams.status == 'inactive'">*</span></label>
							<Datepicker 
								input-class="form-control bg-white" 
								v-model="dataParams.date_seperation"
								
								:config="options" 
							>
							</Datepicker> 
							<input type="text" v-model="dataParams.date_seperation" class="form-control" style="margin-top: -35px;" :required="dataParams.status == 'inactive'">
						</div> 
					</CCol>
				</CRow>
				<CRow>
					<CCol lg="12">
						<label>Reason of Separation <span class="text-danger" v-if="dataParams.status == 'inactive'">*</span></label>
						<CInput
		                  label=""
		                  
		                  v-model="dataParams.reason_of_separation" 
		                  :required="dataParams.status == 'inactive'"
		                />
					</CCol>
				</CRow> 
				
				<CRow>
					<CCol lg="6">
						<CInput
		                  label="Bank Account"
		                 
		                  v-model="dataParams.bank_account" 
						  type="number"
		                />
					</CCol>
					<CCol lg="6">
						<CInput
		                  label="Daily Rate"
		                 
		                  v-model="dataParams.daily_rate" 
						@keypress="isNumber($event)"
		                />
					</CCol>
				</CRow> 
			
				<CRow>
					<CCol lg="12">
						<CInput
		                  label="Remarks"
		                  
		                  v-model="dataParams.remarks" 
		                />
					</CCol> 
				</CRow> 
			</fieldset>
			<fieldset class="fieldset full-line">
			 <legend>Benefits</legend>
				<CRow>
					<CCol lg="6">
						<label>Philhealth No. <span class="text-danger" v-if="is_roadwise()">*</span></label>
						<CInput
		                  v-model="dataParams.philhealth_no" 
						  :required="is_roadwise()"  
						  
		                />
					</CCol>
					<CCol lg="6">
						<label>SSS No. <span class="text-danger" v-if="is_roadwise()">*</span></label>
						<CInput
		                  v-model="dataParams.sss_no" 
						  :required="is_roadwise()"  
		                />
					</CCol>
				</CRow> 
				<CRow>
					<CCol lg="6">
						<label>TIN No. <span class="text-danger" v-if="is_roadwise()">*</span></label>
						<CInput
							v-model="dataParams.tin_no" 
							:required="is_roadwise()"  
		                />
					</CCol>
					<CCol lg="6">
						<label>Pag-IBIG No. <span class="text-danger" v-if="is_roadwise()">*</span></label>
						<CInput
		                  v-model="dataParams.pag_ibig_no" 
						  :required="is_roadwise()"  
		                 
		                />
					</CCol>
				</CRow>
			</fieldset>
			<fieldset class="fieldset full-line">
			 	<legend>Emergency Contact</legend>
				<CRow>
					<CCol lg="6">
						<label>Contact Person Name <span class="text-danger" v-if="is_roadwise()">*</span> </label>
						<CInput
							v-model="dataParams.emergency_name" 
							 :required="is_roadwise()"  
		                 
						/>
					</CCol>
					<CCol lg="6">
						<label>Contact Number <span class="text-danger" v-if="is_roadwise()">*</span></label>
						<CInput
							v-model="dataParams.emergency_contact_no" 
							type="number"
							 :required="is_roadwise()"  
		                 
						/>
					</CCol>
				</CRow> 
				
			</fieldset>
	           	<div slot="footer" class="w-100">
				    <CButton type="submit" style="border-radius: .2rem; color: white;" color="info" class="ml-1 mr-1 float-right">
	                   Save
	                </CButton>
	            	<CButton style="border-radius: .2rem; color: white;" color="dark" class="ml-1 mr-1 float-right" @click="formModal = false">
	                    Cancel
	                </CButton>
	        	</div>	<form @submit.prevent="submit">
			
			
           	</form>			   
           	<div slot="footer" class="w-100"></div>
			</CModal>
	
		<!-- VIEWING DETAILS -->
        <CModal title="Personnel Details" :show.sync="showViewModal" color="info" size="lg"> 
        	
		<fieldset class="fieldset full-line">
			 <legend>Personnel Details</legend>		
					
        		<CRow class="mb-2">
	        		<CCol lg="4">
	        			Name:
	        		</CCol>
	        		<CCol lg="8" style="font-weight:600">
	        			{{dataParams.name}}
	        		</CCol>
	        	</CRow>
	        	<CRow class="mb-2">
	        		<CCol lg="4">
	        			Contact Number:
	        		</CCol>
	        		<CCol lg="8" style="font-weight:600">
	        			{{dataParams.contact_no ? dataParams.contact_no :"-"}}
	        		</CCol>
	        	</CRow>
	        	<CRow class="mb-2">
	        		<CCol lg="4">
	        			Email Address:
	        		</CCol>
	        		<CCol lg="8" style="font-weight:600">
	        			{{dataParams.email ? dataParams.email :"-"}}
	        		</CCol>
	        	</CRow>
	        	<CRow class="mb-2">
	        		<CCol lg="4">
	        			Birthdate:
	        		</CCol>
	        		<CCol lg="8" style="font-weight:600">
	        			{{dataParams.birthday ? dataParams.birthday :"-"}}
	        		</CCol>
	        	</CRow>
	        	<CRow class="mb-2">
	        		<CCol lg="4">
	        			Home Address:
	        		</CCol>
	        		<CCol lg="8" style="font-weight:600">
	        			{{dataParams.home_address ? dataParams.home_address :"-"}}
	        		</CCol>
	        	</CRow>
	        	<CRow class="mb-2">
	        		<CCol lg="4">
	        			Gender:
	        		</CCol>
	        		<CCol lg="8" style="font-weight:600" class="text-uppercase">
	        			{{dataParams.gender ? dataParams.gender :"-"}}
	        		</CCol>
	        	</CRow>
				
		</fieldset>
	        	
		<fieldset class="fieldset full-line">
			 <legend>Employment Details</legend>		
	        	<CRow class="mb-2">
	        		<CCol lg="4">
	        			Personnel Type:
	        		</CCol>
	        		<CCol lg="8" style="font-weight:600">
	        			{{dataParams.personnel_type_name == "" || dataParams.personnel_type_name == null ? "-" : dataParams.personnel_type_name.setting_name}}
	        		</CCol>
	        	</CRow>
	        	<CRow class="mb-2">
	        		<CCol lg="4">
	        			Employment Type:
	        		</CCol>
	        		<CCol lg="8" style="font-weight:600">
	        			{{dataParams.employment_type_label}}
	        		</CCol>
	        	</CRow>
	        	<CRow class="mb-2" v-if="dataParams.employment_type == 'outsourced'">
	        		<CCol lg="4">
	        			Vendor:
	        		</CCol>
	        		<CCol lg="8" style="font-weight:600">
	        			{{dataParams.vendor ? dataParams.vendor.vendor_name :"-"}}
	        		</CCol>
	        	</CRow>
	        	<CRow class="mb-2">
	        		<CCol lg="4">
	        			Employee ID:
	        		</CCol>
	        		<CCol lg="8" style="font-weight:600">
	        			{{dataParams.employee_id ? dataParams.employee_id :"-"}}
	        		</CCol>
	        	</CRow>
	        	<CRow class="mb-2">
	        		<CCol lg="4">
	        			Depot:
	        		</CCol>
	        		<CCol lg="8" style="font-weight:600">
	        			{{dataParams.depot ? dataParams.depot.setting_name :"-"}}
	        		</CCol>
	        	</CRow>
	        	<CRow class="mb-2">
	        		<CCol lg="4">
	        			Date Started:
	        		</CCol>
	        		<CCol lg="8" style="font-weight:600">
	        			{{dataParams.date_started ? dataParams.date_started :"-"}}
	        		</CCol>
	        	</CRow> 
	        	<CRow class="mb-2">
	        		<CCol lg="4">
	        			Date of Separation:
	        		</CCol>
	        		<CCol lg="8" style="font-weight:600">
	        			{{dataParams.date_seperation ? dataParams.date_seperation :"-"}}
	        		</CCol>
	        	</CRow> 
	        	
	        	<CRow class="mb-2">
	        		<CCol lg="4">
	        			Bank Account:
	        		</CCol>
	        		<CCol lg="8" style="font-weight:600">
	        			{{dataParams.bank_account ? dataParams.bank_account :"-"}}
	        		</CCol>
	        	</CRow> 
	        	<CRow class="mb-2">
	        		<CCol lg="4">
	        			Daily Rate:
	        		</CCol>
	        		<CCol lg="8" style="font-weight:600">
	        			{{dataParams.daily_rate ? dataParams.daily_rate :"-"}}
	        		</CCol>
	        	</CRow> 
	        	
	        	<CRow class="mb-2">
	        		<CCol lg="4">
	        			Remarks:
	        		</CCol>
	        		<CCol lg="8" style="font-weight:600">
	        			{{dataParams.remarks ? dataParams.remarks :"-" }}
	        		</CCol>
	        	</CRow> 

	        	<CRow class="mb-2">
	        		<CCol lg="4">
	        			Reason of Separation:
	        		</CCol>
	        		<CCol lg="8" style="font-weight:600">
	        			{{dataParams.reason_of_separation ? dataParams.reason_of_separation :"-"}}
	        		</CCol>
	        	</CRow> 
		</fieldset>

		<fieldset class="fieldset full-line">
			 <legend>Benefits</legend>	
				
				<CRow class="mb-2">
	        		<CCol lg="3">
	        			Philhealth No.:
	        		</CCol>
	        		<CCol lg="3" style="font-weight:600">
	        			{{dataParams.philhealth_no ? dataParams.philhealth_no :"-"}}
	        		</CCol>
					<CCol lg="3">
	        			SSS No.:
	        		</CCol>
	        		<CCol lg="3" style="font-weight:600">
	        			{{dataParams.sss_no ? dataParams.sss_no :"-"}}
	        		</CCol>
	        	</CRow> 
	        	<!-- <CRow class="mb-2">
	        		
	        	</CRow>  -->
	        	<CRow class="mb-2">
	        		<CCol lg="3">
	        			TIN No.:
	        		</CCol>
	        		<CCol lg="3" style="font-weight:600">
	        			{{dataParams.tin_no ? dataParams.tin_no :"-"}}
	        		</CCol>
					<CCol lg="3">
	        			Pag-IBIG No.:
	        		</CCol>
	        		<CCol lg="3" style="font-weight:600">
	        			{{dataParams.pag_ibig_no ? dataParams.pag_ibig_no :"-"}}
	        		</CCol>
	        	</CRow> 
	        	<!-- <CRow class="mb-2">
	        		
	        	</CRow>  -->
		</fieldset>
	    <fieldset class="fieldset full-line">
			 <legend>Emergency Contact</legend>	
	        	<CRow class="mb-2">
	        		<CCol lg="4">
	        			Contact Person Name:
	        		</CCol>
	        		<CCol lg="8" style="font-weight:600">
	        			{{dataParams.emergency_name ? dataParams.emergency_name :"-"}}
	        		</CCol>
	        	</CRow> 
	        	<CRow class="mb-2">
	        		<CCol lg="4">
	        			Contact Number:
	        		</CCol>
	        		<CCol lg="8" style="font-weight:600">
	        			{{dataParams.emergency_contact_no ? dataParams.emergency_contact_no :"-"}}
	        		</CCol>
	        	</CRow> 
		</fieldset>		
        	
        	<div slot="footer" class="w-100">
            	<CButton style="border-radius: .2rem; color: white;" color="dark" class="ml-1 mr-1 float-right" @click="showViewModal = false">
                    Close
                </CButton> 
                
            </div>
        	<div slot="footer" class="w-100"></div>
        </CModal>
		</CCard>
	</div>
</template>
<script> 
import config from '../../config.js';
import axios from '../../axios';
import Swal from 'sweetalert2';
import Search from './search'
import Datepicker from 'vuejs-datepicker';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import moment from 'moment';
import '../../style.css';

export default {
	mounted(){ 
		this.getDataAll();
		this.getData();
		this.getDataInactive();
		this.getVendor();
		this.getDepot();
		this.getPersonnelType();
		for(var i = 0; i < document.querySelectorAll('.nav-item').length; i++){
			document.querySelectorAll('.nav-item')[i].onclick = ()=>{
				this.current_tab = document.querySelector(".nav-link.active").innerHTML;
				this.setCount();
			}
		}
		
		

	},
	data(){
		return{
			config,
			options:'',
			title:'',
			isLoading: false,
			formModal: false,
			showViewModal: false,
			dataListAll: [],
			dataList: [],
			dataListInactive: [],
			current_tab: "All",
			count: 0,
			depotName: "",
			depot_id: JSON.parse(window.localStorage.getItem('user_data')).depot_id,
			dataParams: {
				name: "",
				contact_no: "",
				email: "",
				birthday: "",
				home_address: "",
				gender: "",
				personnel_type_name: "",
				employment_type: "direct_hire",
				vendor_id: "",
				employee_id: "",
				depot_id: JSON.parse(window.localStorage.getItem('user_data')).depot_id != null ? JSON.parse(window.localStorage.getItem('user_data')).depot_id : "",
				date_started: "",
				date_seperation: "",
				philhealth_no: "",
				sss_no: "",
				status: "active",
				remarks: "",
				emergency_name: "",
				emergency_contact_no: "",
				bank_account: "",
				daily_rate: "",
				tin_no: "",
				pag_ibig_no: "",
				reason_of_separation: "",

			},
			fields: [
				{
					key: 'name', 
					label: 'Name'
				},
				{
					key: 'personnel_type_label', 
					label: 'Type'
				},
				// {
				// 	key: 'email_label', 
				// 	label: 'Email'
				// },
				
				{
					key: 'depot_label', 
					label: 'Depot'
				},
				{
					key: 'employment_type_label', 
					label: 'Employment'
				},
				{
					key: 'vendor_label', 
					label: 'Vendor'
				},
				{
					key: 'contact_no', 
					label: 'Contact'
				},
				{
					key: 'daily_rate', 
					label: 'Daily Rate'
				},
				{
					key: 'status', 
					label: 'Status'
				},
				{
					key: 'action',
					label: 'Action',
					_style: { width: '20px'}
				}
			],
			currentPageAll: 1,
			currentPage: 1,
			currentPageInactive: 1,
			editMode: false,
			selectedRow: {},
			showCollapse: false,
			filter: {
				name: "",
				personnel_type: "",
				home_address: "",
				depot_id: "",
				employment_type: "",
				contact_no: "",
				vendor_id:"",
			},
			vendorList: {
				data: []
			},
			depotList: {
				data:[]
			},
			personnelTypeList: {
				data: []
			},
			setting_type : ''
		}
	},
	name: 'Tables',
	components: {Search, Datepicker, vSelect},
	watch: {
		'dataParams.name' : function(val) {
			this.dataParams.name = val.toUpperCase()
		}
	},
	methods: {
		changeSetting(type) {
			this.setting_type = type;
		},
		setCount(){
			if(this.current_tab == "All"){
				this.count = this.dataListAll.total
			}
			if(this.current_tab == "Active"){
				this.count = this.dataList.total
			}
			if(this.current_tab == "Inactive"){
				this.count = this.dataListInactive.total
			}
		},
		
		 getBadge (status) {
    	return status === 'active' ? 'success'
             : status === 'inactive' ? 'danger' : 'primary'
    },
		updatePage(data){
			this.currentPage = data;
			this.getData();
		
		}, 
		updatePageAll(data){
			this.currentPageAll = data;
			this.getDataAll();
		}, 
		updatePageInactive(data){
			this.currentPageInactive = data;
			this.getDataInactive();
		}, 

	    toggleModal(){
			this.title = "Personnel";
	    	this.formModal = !this.formModal;
			
	    },

	    submit(){
	    	var ax = {};
	    	if(this.dataParams.vendor_id == ""){
	    		this.dataParams.vendor_id = null;
	    	}

			if(this.dataParams.employment_type == 'direct_hire') {
				this.dataParams.vendor_id = null;
			}

	    	if(this.dataParams.employee_id == ""){
	    		this.dataParams.employee_id = null;
	    	}  

	    	if(this.dataParams.birthday == "" || this.dataParams.birthday == null){
	    		this.dataParams.birthday = null;
	    	}
	    	else{
	    		this.dataParams.birthday = moment(this.dataParams.birthday).format('YYYY-MM-DD');
	    	}

	    	if(this.dataParams.date_seperation == "" || this.dataParams.date_seperation == null){
	    		this.dataParams.date_seperation = null;
	    	}
	    	else{
	    		this.dataParams.date_seperation = moment(this.dataParams.date_seperation).format('YYYY-MM-DD');
	    	}

	    	if(this.dataParams.date_started == "" || this.dataParams.date_started == null){
	    		this.dataParams.date_started = null;
	    	}
	    	else{
	    		this.dataParams.date_started = moment(this.dataParams.date_started).format('YYYY-MM-DD');
	    	}

			this.dataParams.daily_rate = (this.dataParams.daily_rate) ? this.dataParams.daily_rate : 0;

			this.dataParams.is_company_with_agency = config.global_user_data.company && config.global_user_data.company.company_type.toLowerCase() === 'trucking with agency' ? 1 : 0;
	    	
	    	if(this.editMode){
	    		ax = axios.put(config.api_path+"/personnel/"+this.selectedRow.id, this.dataParams)
	    	}
	    	else{
	    		ax = axios.post(config.api_path+"/personnel", this.dataParams)
	    	}
	    	ax.then(response => {
				const { status } = response.data;
				
				if(status === 'duplicate') {
					Swal.fire({
						title: 'Personnel name already exists.',
						text,
						icon: 'error', 
					})
						return;
				}

	    		var text = 'Personnel successfully added!';
	    		if(this.editMode){
	    			text = 'Personnel successfully updated!';
	    		}
	    		Swal.fire({
					title: 'Success!',
					text,
					icon: 'success', 
				})
	    		this.formModal = false
	    		this.editMode = false;
	    		this.dataParams = {
					name: "",
					contact_no: "",
					email: "",
					birthday: "",
					home_address: "",
					gender: "",
					personnel_type: "",
					employment_type: "direct_hire",
					vendor_id: "",
					employee_id: "",
					depot_id: "",
					date_started: "",
					date_seperation: "",
					philhealth_no: "",
					sss_no: "",
					status: "active",
					remarks: "",
					emergency_name: "",
					emergency_contact_no: "",
					bank_account: "",
					daily_rate: "",
				}
	    		this.getData();
	    		this.getDataInactive();
				this.getDataAll();
	    	})
	    },

	    search(event){
	    	this.filter = event; 
	    	this.getData();
			this.getDataAll();
	    	this.getDataInactive();
	    },
		 getDataAll(){ 

           	this.isLoading = true;
	    	var employment_type = this.filter.employment_type;
	    	if(employment_type == "all" || employment_type == null){
	    		employment_type = ""
	    	}
	    	var personnel_type = this.filter.personnel_type;
	    	if(personnel_type == "all" || personnel_type == null){
	    		personnel_type = ""
	    	}
	    	var depot_id = this.filter.depot_id;
	    	if(depot_id == "all" || depot_id == null){
	    		depot_id = ""
	    	}
			var vendor_id = this.filter.vendor_id;
	    	if(vendor_id == "all" || vendor_id == null){
	    		vendor_id = ""
	    	}
	    	axios.get(config.api_path+'/personnel?status=&contact_no='+this.filter.contact_no
			+'&name='+this.filter.name
			+'&personnel_type='+personnel_type
			+'&home_address='+this.filter.home_address
			+'&depot_id='+depot_id
			+'&vendor_id='+vendor_id
			+'&employment_type='+employment_type
			+'&page='+this.currentPageAll
			+'&limit=20')

	    	.then(response => {
	    		this.dataListAll = response.data; 
	    		this.dataListAll.data = this.dataListAll.data.map((value, index)=>{
	    			if(value.account_code == ""){
	    				value.account_code = "-";
	    			}
	    			if( value.home_address== null){
	    				value.home_address = "-";
	    			}
	    			if(value.personnel_type_name){
	    				value.personnel_type_label = value.personnel_type_name.setting_name;
	    			}
	    			else{
	    				value.personnel_type_label = "-"
	    			}
	    			if(value.personnel_type){
	    				value.personnel_type = parseInt(value.personnel_type)
	    			}
	    			if(value.employment_type == "direct_hire"){
	    				value.employment_type_label = "Direct Hired";
	    			}
	    			else{
	    				value.employment_type_label = "Outsourced";
	    			}

	    			value.email_label = value.email; 
	    			if(value.email_label == "" || value.email_label == null){
	    				value.email_label = "-";
	    			}

	    			value.home_address_label = value.home_address; 
	    			if(value.home_address_label == "" || value.home_address_label == null){
	    				value.home_address_label = "-";
	    			}
					
					if(value.daily_rate == "" || value.daily_rate == null){
	    				value.daily_rate = "-";
	    			}

	    			value.depot_label = "-"
	    			if(value.depot){
	    				value.depot_label = value.depot.setting_name;
	    			}
					value.vendor_label = " "
	    			if(value.vendor){
	    				value.vendor_label = value.vendor.vendor_name;
	    			}

					value.contact_no = value.contact_no ? value.contact_no ?? '' : '';
	    			
	    			
	    			return value;
	    		}); 
				this.setCount()
	    	})
			.catch(err => {
				console.error(err)
				this.isLoading = false;
			})
			.finally(fin => {
				console.log(fin)
				this.isLoading = false;	
			})
	    },
	    getData(){ 
	   this.isLoading = true;
	    	var employment_type = this.filter.employment_type;
	    	if(employment_type == "all" || employment_type == null){
	    		employment_type = ""
	    	}
	    	var personnel_type = this.filter.personnel_type;
	    	if(personnel_type == "all" || personnel_type == null){
	    		personnel_type = ""
	    	}
	    	var depot_id = this.filter.depot_id;
	    	if(depot_id == "all" || depot_id == null){
	    		depot_id = ""
	    	}
			var vendor_id = this.filter.vendor_id;
	    	if(vendor_id == "all" || vendor_id == null){
	    		vendor_id = ""
	    	}
	    	axios.get(config.api_path+'/personnel?status=active&contact_no='+this.filter.contact_no
			+'&name='+this.filter.name
			+'&personnel_type='+personnel_type
			+'&home_address='+this.filter.home_address
			+'&depot_id='+depot_id
			+'&vendor_id='+vendor_id
			+'&employment_type='+employment_type
			+'&page='+this.currentPage
			+'&limit=20')
	    	.then(response => {
	    		this.dataList = response.data; 
	    		this.dataList.data = this.dataList.data.map((value, index)=>{
	    			if(value.account_code == ""){
	    				value.account_code = "-";
	    			}
	    			if(value.home_address == ""){
	    				value.home_address = "-";
	    			}
	    			if(value.personnel_type_name){
	    				value.personnel_type_label = value.personnel_type_name.setting_name;
	    			}
	    			else{
	    				value.personnel_type_label = "-"
	    			}
	    			if(value.personnel_type){
	    				value.personnel_type = parseInt(value.personnel_type)
	    			}
	    			if(value.employment_type == "direct_hire"){
	    				value.employment_type_label = "Direct Hired";
	    			}
	    			else{
	    				value.employment_type_label = "Outsourced";
	    			}
	    			
	    			value.email_label = value.email; 
	    			if(value.email_label == "" || value.email_label == null){
	    				value.email_label = "-";
	    			}

	    			value.home_address_label = value.home_address; 
	    			if(value.home_address_label == "" || value.home_address_label == null){
	    				value.home_address_label = "-";
	    			}
					if(value.daily_rate == "" || value.daily_rate == null){
	    				value.daily_rate = "-";
	    			}
					value.vendor_label = "" 
	    			if(value.vendor){
	    				value.vendor_label = value.vendor.vendor_name;
	    			}

	    			value.depot_label = "-"
	    			if(value.depot){
	    				value.depot_label = value.depot.setting_name;
	    			}
					value.contact_no = value.contact_no ? value.contact_no ?? '' : '';
					
					// value.contact_no = value.contact_no ?? '-'

	    			return value;
	    		}); 
				this.setCount()
	    	})
			.catch(err => {
				console.error(err)
				this.isLoading = false;
			})
			.finally(fin => {
				console.log(fin)
				this.isLoading = false;	
			})
	    },

	    getDataInactive(){

		this.isLoading = true;
	    	var vendor_type = this.filter.vendor_type;
	    	if(vendor_type == "all"){
	    		vendor_type = ""
	    	}

	    	var personnel_type = this.filter.personnel_type;
	    	if(personnel_type == "all" || personnel_type == null){
	    		personnel_type = ""
	    	}
	    	var depot_id = this.filter.depot_id;
	    	if(depot_id == "all" || depot_id == null){
	    		depot_id = ""

			var vendor_id = this.filter.vendor_id;
	    	if(vendor_id == "all" || vendor_id == null){
	    		vendor_id = ""
	    	}
	    	}
	    	axios.get(config.api_path
			+'/personnel?status=inactive&contact_no='+this.filter.contact_no
			+'&name='+this.filter.name
			+'&personnel_type='+this.filter.personnel_type
			+'&home_address='+this.filter.home_address
			+'&depot_id='+this.filter.depot_id
			+'&vendor_id='+this.filter.vendor_id
			+'&employment_type='+this.filter.employment_type
			+'&page='+this.currentPageInactive
			+'&limit=20')
	    	.then(response => {
	    		this.dataListInactive = response.data; 
	    		this.dataListInactive.data = this.dataListInactive.data.map((value, index)=>{
	    			if(value.account_code == ""){
	    				value.account_code = "-";
	    			}
	    			if(value.home_address == ""){
	    				value.home_address = "-";
	    			}
	    			if(value.personnel_type_name){
	    				value.personnel_type_label = value.personnel_type_name.setting_name;
	    			}
	    			else{
	    				value.personnel_type_label = "-"
	    			}
	    			if(value.personnel_type){
	    				value.personnel_type = parseInt(value.personnel_type)
	    			}
	    			if(value.employment_type == "direct_hire"){
	    				value.employment_type_label = "Direct Hired";
	    			}
	    			else{
	    				value.employment_type_label = "Outsourced";
	    			}
	    			
	    			value.email_label = value.email; 
	    			if(value.email_label == "" || value.email_label == null){
	    				value.email_label = "-";
	    			}

	    			value.home_address_label = value.home_address; 
	    			if(value.home_address_label == "" || value.home_address_label == null){
	    				value.home_address_label = "-";
	    			}
					if(value.daily_rate == "" || value.daily_rate == null){
	    				value.daily_rate = "-";
	    			}

					value.vendor_label = " "
	    			if(value.vendor){
	    				value.vendor_label = value.vendor.vendor_name;
	    			}
	    			value.depot_label = "-"
	    			if(value.depot){
	    				value.depot_label = value.depot.setting_name;
	    			}

					value.contact_no = value.contact_no ? value.contact_no ?? '' : '';
	    			
	    			return value;
	    		}); 
				this.setCount()
				
	    	})
			.catch(err => {
				console.error(err)
				this.isLoading = false;
			})
			.finally(fin => {
				console.log(fin)
				this.isLoading = false;	
			})
	    },

	    updateModal(item){
			// this.title = "Update Personnel";
	    	// this.selectedRow = item;
	    	// this.dataParams = {...item};
	    	// this.formModal = true;
	    	// this.editMode = true;
			window.open('/#/data/update_personnel/'+item.id, '_blank');
			

	    },

	    viewModal(item){

	    	// this.selectedRow = item;
	    	// this.dataParams = item;
	    	// this.showViewModal = true; 
			window.open('/#/data/view_personnel/'+item.id, '_blank');
			

	    },

	    deleteRow(item){
	    	Swal.fire({
				icon:'warning'
,				text: 'Are you sure you want to delete this personnel?', 
				reverseButtons: true,
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Yes, delete it!'
			}).then((result) => { 
				if (result.isConfirmed) {
					axios.delete(config.api_path+'/personnel/'+item.id)
			    	.then(response => {
						const {status, has_booking} = response.data;
						this.$showLoading(false)
						if(has_booking == 1) {
							Swal.fire({
								title: 'Delete failed!',
								text: "A personnel that was already assigned to a booking cannot be deleted. Please contact a support.",
								icon: 'error', 
							})
						}
						else {
			    		
						this.getData();
	    				this.getDataInactive();
						this.getDataAll();
			    		Swal.fire({
							title: 'Success!',
							text: "Personnel successfully deleted",
							icon: 'success', 
						})
					}
			    	}) 
				}
			})  
	    },

	    updateGender(data){
	    	this.dataParams.gender = data;
	    },
		
	    getVendor(){

	    	axios.get(config.api_path+'/vendor?page=1&limit=10000000')
	    	.then(response => {
	    		this.vendorList = response.data;
	    		this.vendorList.data = this.vendorList.data.map((value, index)=>{
	    			value.value = value.id;
	    			value.label = value.vendor_name;
	    			return value;
	    		})

				
	    	})

	    },
		getPersonnelType(){
          axios.get(config.api_path+'/reference/settings-list',{
              params:{
                setting_type:'personnel_type'
              }
                })
          .then(response => {
            this.personnelTypeList = response.data; 
          })

          },

	   
		getDepot(){
          axios.get(config.api_path+'/reference/settings-list',{
              params:{
                setting_type:'depot'
              }
                })
          .then(response => {
            this.depotList = response.data; 
          })

          },

	    // getDepot(){

	    // 	axios.get(config.api_path+'/setting?setting_type=depot&page=1&limit=10000000')
	    // 	.then(response => {
	    // 		this.depotList = response.data;
	    // 		this.depotList.data = this.depotList.data.map((value, index)=>{
	    // 			value.value = value.id;
	    // 			value.label = value.setting_name;
	    // 			return value;
	    // 		})
	    // 	})

	    // },

	    updateVendorType(data){
	    	this.dataParams.vendor_type = data;
	    },

	    updatePersonnelType(data){
	    	this.dataParams.personnel_type = data;
	    },

	    updateEmploymentType(data){
			console.log(data)
	    	this.dataParams.employment_type = data;
	    },

	    updateVendor(data){
	    	this.dataParams.vendor_id = data;
	    },

	    updateDepot(data){
	    	this.dataParams.depot_id = data;
	    },

	    updateStatus(data){
	    	this.dataParams.status = data;
	    },

		download() {
			
	    	var employment_type = this.filter.employment_type;
	    	if(employment_type == "all" || employment_type == null){
	    		employment_type = ""
	    	}
	    	var personnel_type = this.filter.personnel_type;
	    	if(personnel_type == "all" || personnel_type == null){
	    		personnel_type = ""
	    	}
	    	var depot_id = this.filter.depot_id;
	    	if(depot_id == "all" || depot_id == null){
	    		depot_id = ""
	    	}
			var vendor_id = this.filter.vendor_id;
	    	if(vendor_id == "all" || vendor_id == null){
	    		vendor_id = ""
	    	}
			let status = this.setting_type;

			this.$showLoading(true)
	    	
	    	axios.get(config.api_path+'/personnel/report/list',{
				params: {
					status,
					contact_no : this.filter.contact_no,
					name : this.filter.name,
					personnel_type,
					home_address : this.filter.home_address,
					depot_id,
					employment_type,
					vendor_id,
				}
			})
	    	.then(response => {
				this.$showLoading(false)
				window.open(config.main_path.replace('/index.php', '')+'/'+response.data.file) 
	
				setTimeout(() => {
					axios.get(`${config.api_path}/remove-file`,{
						params : {
							filename : response.data.file
						}
					})	
				}, 2000);
			})
			.catch(err => {
				this.$showLoading(false)
			})
		}

 	}
}
</script>
